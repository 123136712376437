import React from "react";

const Hc = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          High cholesterol
        </p>

        <p className="text-xl text-gray-800 mb-2">Definition</p>
        <p className="text-md text-gray-600 mb-2">
          Cholesterol is a waxy substance that’s found in the fats (lipids) in
          your blood. While your body needs cholesterol to continue building
          healthy cells, having high cholesterol can increase your risk of heart
          disease.
        </p>
        <p className="text-md text-gray-600 mb-2">
          When you have high cholesterol, you may develop fatty deposits in your
          blood vessels. Eventually, these deposits make it difficult for enough
          blood to flow through your arteries. Your heart may not get as much
          oxygen-rich blood as it needs, which increases the risk of a heart
          attack. Decreased blood flow to your brain can cause a stroke.
        </p>
        <p className="text-md text-gray-600 mb-12">
          High cholesterol (hypercholesterolemia) can be inherited, but is often
          preventable and treatable. A healthy diet, regular exercise and
          sometimes medication can go a long way toward reducing high
          cholesterol.
        </p>

        <p className="text-xl text-gray-800 mb-2">Symptoms</p>
        <p className="text-md text-gray-600 mb-12">
          High cholesterol has no symptoms. A blood test is the only way to
          detect high cholesterol.
        </p>

        <p className="text-xl text-gray-800 mb-2">When to see a doctor</p>
        <p className="text-md text-gray-600 mb-12">
          Ask your doctor for a baseline cholesterol test at age 20 and then
          have your cholesterol retested at least every five years. If your test
          results aren’t within desirable ranges, your doctor may recommend more
          frequent measurements. Your doctor may also suggest you have more
          frequent tests if you have a family history of high cholesterol, heart
          disease or other risk factors, like smoking, diabetes or high blood
          pressure.
        </p>

        <p className="text-xl text-gray-800 mb-2">Causes</p>
        <p className="text-md text-gray-600 mb-2">
          Cholesterol is carried through your blood, attached to proteins. This
          combination of proteins and cholesterol is called a lipoprotein. You
          may have heard of different types of cholesterol, based on what type
          of cholesterol the lipoprotein carries. They are:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Low-density lipoprotein (LDL). LDL, or “bad,” cholesterol transports
            cholesterol particles throughout your body. LDL cholesterol builds
            up in the walls of your arteries, making them hard and narrow.
          </li>
          <li>
            Very-low-density lipoprotein (VLDL). This type of lipoprotein
            contains the most triglycerides, a type of fat, attached to the
            proteins in your blood. VLDL cholesterol makes LDL cholesterol
            larger in size, causing your blood vessels to narrow. If you’re
            taking cholesterol-lowering medication but have a high VLDL level,
            you may need additional medication to lower your triglycerides.
          </li>
          <li>
            High-density lipoprotein (HDL). HDL, or “good,” cholesterol picks up
            excess cholesterol and takes it back to your liver.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Factors within your control — such as inactivity, obesity and an
          unhealthy diet — contribute to high LDL cholesterol and low HDL
          cholesterol. Factors beyond your control may play a role, too. For
          example, your genetic makeup may keep cells from removing LDL
          cholesterol from your blood efficiently or cause your liver to produce
          too much cholesterol.
        </p>

        <p className="text-xl text-gray-800 mb-2">Risk factors</p>
        <p className="text-md text-gray-600 mb-2">
          You’re more likely to have high cholesterol that can lead to heart
          disease if you have any of these risk factors:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Smoking. Cigarette smoking damages the walls of your blood vessels,
            making them likely to accumulate fatty deposits. Smoking may also
            lower your level of HDL, or “good,” cholesterol.
          </li>
          <li>
            Obesity. Having a body mass index (BMI) of 30 or greater puts you at
            risk of high cholesterol.
          </li>
          <li>
            Poor diet. Foods that are high in cholesterol, such as red meat and
            full-fat dairy products, will increase your total cholesterol.
            Eating saturated fat, found in animal products, and trans fats,
            found in some commercially baked cookies and crackers, also can
            raise your cholesterol level.
          </li>
          <li>
            Lack of exercise. Exercise helps boost your body’s HDL “good”
            cholesterol while lowering your LDL “bad” cholesterol. Not getting
            enough exercise puts you at risk of high cholesterol.
          </li>
          <li>
            High blood pressure. Increased pressure on your artery walls damages
            your arteries, which can speed the accumulation of fatty deposits.
          </li>
          <li>
            Diabetes. High blood sugar contributes to higher LDL cholesterol and
            lower HDL cholesterol. High blood sugar also damages the lining of
            your arteries.
          </li>
          <li>
            Family history of heart disease. If a parent or sibling developed
            heart disease before age 55, high cholesterol levels place you at a
            greater than average risk of developing heart disease.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Complications</p>
        <p className="text-md text-gray-600 mb-2">
          High cholesterol can cause atherosclerosis, a dangerous accumulation
          of cholesterol and other deposits on the walls of your arteries. These
          deposits — called plaques — can reduce blood flow through your
          arteries, which can cause complications, such as:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Chest pain. If the arteries that supply your heart with blood
            (coronary arteries) are affected, you may have chest pain (angina)
            and other symptoms of coronary artery disease.
          </li>
          <li>
            Heart attack. If plaques tear or rupture, a blood clot may form at
            the plaque-rupture site — blocking the flow of blood or breaking
            free and plugging an artery downstream. If blood flow to part of
            your heart stops, you’ll have a heart attack.
          </li>
          <li>
            Stroke. Similar to a heart attack, if blood flow to part of your
            brain is blocked by a blood clot, a stroke occurs.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Tests and diagnosis</p>
        <p className="text-md text-gray-600 mb-2">
          A blood test to check cholesterol levels — called a lipid panel or
          lipid profile — typically reports:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Total cholesterol</li>
          <li>LDL cholesterol</li>
          <li>HDL cholesterol</li>
          <li>Triglycerides — a type of fat in the blood</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          For the most accurate measurements, don’t eat or drink anything (other
          than water) for nine to 12 hours before the blood sample is taken.
        </p>

        <p className="text-xl text-gray-800 mb-2">Interpreting the numbers</p>
        <p className="text-md text-gray-600 mb-3">
          Cholesterol levels are measured in milligrams (mg) of cholesterol per
          deciliter (dL) of blood in the United States and some other countries.
          Canada and most European countries measure cholesterol in millimoles
          (mmol) per liter (L) of blood. Consider these general guidelines when
          you get your lipid panel (cholesterol test) results back to see if
          your cholesterol falls in ideal levels.
        </p>
        <p className="text-lg text-gray-700 mb-2">
          Total cholesterol (U.S. and some other countries)
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-3">
          <li>Below 200 mg/dL: Best</li>
          <li>200-239 mg/dL: Borderline high</li>
          <li>240 mg/dL and above: High</li>
        </ul>
        <p className="text-lg text-gray-700 mb-2">
          LDL cholesterol (U.S. and some other countries)
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-3">
          <li>Below 70 mg/dL: Best for people at high risk of heart disease</li>
          <li>Below 100 mg/dL: Best for people at risk for heart disease</li>
          <li>100-129 mg/dL: Near ideal</li>
          <li>130-159 mg/dL: Borderline high</li>
          <li>160-189 mg/dL: High</li>
          <li>190 mg/dL and above: Very high</li>
        </ul>
        <p className="text-lg text-gray-700 mb-2">
          HDL cholesterol (U.S. and some other countries)
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-3">
          <li>Below 40 mg/dL (men): Poor</li>
          <li>Below 50 mg/dL (women): Poor</li>
          <li>50-59 mg/dL: Better</li>
          <li>60 mg/dL and above: Best</li>
        </ul>
        <p className="text-lg text-gray-700 mb-2">
          Triglycerides (U.S. and some other countries)
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-3">
          <li>Below 150 mg/dL: Best</li>
          <li>150-199 mg/dL: Borderline high</li>
          <li>200-499 mg/dL: High</li>
          <li>500 mg/dL and above: Very high</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          The American Heart Association (AHA) recommends that a triglyceride
          level of 100 mg/dL (1.3 mmol/L) or lower is considered “optimal.” The
          AHA says this optimal level would improve your heart health. However,
          the AHA doesn’t recommend drug treatment to reach this level. Instead,
          for those trying to lower their triglycerides to this level, lifestyle
          changes such as diet, weight loss and physical activity are
          encouraged. That’s because triglycerides usually respond well to
          dietary and lifestyle changes.
        </p>

        <p className="text-xl text-gray-800 mb-2">LDL targets differ</p>
        <p className="text-md text-gray-600 mb-2">
          Because LDL cholesterol is a major risk factor for heart disease, it’s
          the main focus of cholesterol-lowering treatment. Your target LDL
          number can vary, depending on your underlying risk of heart disease.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Most people should aim for an LDL level below 130 mg/dL (3.4 mmol/L).
          If you have other risk factors for heart disease, your target LDL may
          be below 100 mg/dL (2.6 mmol/L). If you’re at very high risk of heart
          disease, you may need to aim for an LDL level below 70 mg/dL (1.8
          mmol/L). In general, the lower your LDL cholesterol level is, the
          better.
        </p>
        <p className="text-md text-gray-600 mb-2">
          You’re considered to be at a high risk of heart disease if you:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Have had a previous heart attack or stroke</li>
          <li>Have artery blockages in your neck (carotid artery disease)</li>
          <li>
            Have artery blockages in your arms or legs (peripheral artery
            disease)
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          In addition, two or more of the following risk factors might also
          place you in the high-risk group:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>Smoking</li>
          <li>High blood pressure</li>
          <li>Low HDL cholesterol</li>
          <li>Diabetes</li>
          <li>Family history of early heart disease</li>
          <li>
            Age older than 45 if you’re a man, or older than 55 if you’re a
            woman
          </li>
          <li>
            Elevated lipoprotein (a), another type of fat (lipid) in your blood
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Treatments and drugs</p>
        <p className="text-md text-gray-600 mb-2">
          Lifestyle changes such as exercising and eating a healthy diet are the
          first line of defense against high cholesterol. But, if you’ve made
          these important lifestyle changes and your total cholesterol — and
          particularly your LDL cholesterol — remains high, your doctor may
          recommend medication.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The specific choice of medication or combination of medications
          depends on various factors, including your individual risk factors,
          your age, your current health and possible side effects. Common
          choices include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Statins. Statins — among the most commonly prescribed medications
            for lowering cholesterol — block a substance your liver needs to
            make cholesterol. This causes your liver to remove cholesterol from
            your blood. Statins may also help your body reabsorb cholesterol
            from built up deposits on your artery walls, potentially reversing
            coronary artery disease. Choices include atorvastatin (Lipitor),
            fluvastatin (Lescol), lovastatin (Altoprev, Mevacor), pravastatin
            (Pravachol), rosuvastatin (Crestor) and simvastatin (Zocor).
          </li>
          <li>
            Bile-acid-binding resins. Your liver uses cholesterol to make bile
            acids, a substance needed for digestion. The medications
            cholestyramine (Prevalite, Questran), colesevelam (Welchol) and
            colestipol (Colestid) lower cholesterol indirectly by binding to
            bile acids. This prompts your liver to use excess cholesterol to
            make more bile acids, which reduces the level of cholesterol in your
            blood.
          </li>
          <li>
            Cholesterol absorption inhibitors. Your small intestine absorbs the
            cholesterol from your diet and releases it into your bloodstream.
            The drug ezetimibe (Zetia) helps reduce blood cholesterol by
            limiting the absorption of dietary cholesterol. Zetia can be used in
            combination with any of the statin drugs.
          </li>
          <li>
            Combination cholesterol absorption inhibitor and statin. The
            combination drug ezetimibe-simvastatin (Vytorin) decreases both
            absorption of dietary cholesterol in your small intestine and
            production of cholesterol in your liver. It’s unknown whether
            Vytorin is more effective in reducing heart disease risk than taking
            simvastatin by itself.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">
          Medications for high triglycerides
        </p>
        <p className="text-md text-gray-600 mb-2">
          If you also have high triglycerides, your doctor may prescribe:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Fibrates. The medications fenofibrate (Lofibra, TriCor) and
            gemfibrozil (Lopid) decrease triglycerides by reducing your liver’s
            production of very-low-density lipoprotein (VLDL) cholesterol and by
            speeding up the removal of triglycerides from your blood. VLDL
            cholesterol contains mostly triglycerides.
          </li>
          <li>
            Niacin. Niacin (Niaspan) decreases triglycerides by limiting your
            liver’s ability to produce LDL and VLDL cholesterol. Prescription
            and over-the-counter niacin is available, but prescription niacin is
            preferred as it has the least side effects. Dietary supplements
            containing niacin that are available over-the-counter are not
            effective for lowering triglycerides, and may damage your liver.
          </li>
          <li>
            Omega-3 fatty acid supplements. Omega-3 fatty acid supplements can
            help lower your cholesterol. You can take over-the-counter
            supplements, or your doctor may prescribe Lovaza, a prescription
            omega-3 fatty acid supplement, as a way to lower your triglycerides.
            Lovaza may be taken with another cholesterol-lowering medication,
            such as a statin. If you choose to take over-the-counter
            supplements, get your doctor’s OK first. Omega-3 fatty acid
            supplements could affect other medications you’re taking.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Effectiveness varies</p>
        <p className="text-md text-gray-600 mb-12">
          Most cholesterol medications are well tolerated, but effectiveness
          varies from person to person. The common side effects are muscle
          pains, stomach pain, constipation, nausea and diarrhea. If you decide
          to take cholesterol medication, your doctor may recommend liver
          function tests every few months to monitor the medication’s effect on
          your liver.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Lifestyle and home remedies
        </p>
        <p className="text-md text-gray-600 mb-12">
          Lifestyle changes are essential to improve your cholesterol level. To
          bring your numbers down, lose excess weight, eat healthy foods and
          increase your physical activity. If you smoke, quit.
        </p>

        <p className="text-xl text-gray-800 mb-2">Lose extra pounds</p>
        <p className="text-md text-gray-600 mb-12">
          Excess weight contributes to high cholesterol. Losing even 5 to 10
          pounds can help lower total cholesterol levels. Start by taking an
          honest look at your eating habits and daily routine. Consider your
          challenges to weight loss — and ways to overcome them. Set long-term,
          sustainable goals.
        </p>

        <p className="text-xl text-gray-800 mb-2">Eat heart-healthy foods</p>
        <p className="text-md text-gray-600 mb-2">
          What you eat has a direct impact on your cholesterol level. In fact, a
          diet rich in fiber and other cholesterol-lowering foods may help lower
          cholesterol as much as statin medication for some people.
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Choose healthier fats. Saturated fat and trans fat raise your total
            cholesterol and LDL cholesterol. Get no more than 10 percent of your
            daily calories from saturated fat. Monounsaturated fat — found in
            olive, peanut and canola oils — is a healthier option. Almonds and
            walnuts are other sources of healthy fat.
          </li>
          <li>
            Eliminate trans fats. Trans fats, which are often found in
            margarines and commercially baked cookies, crackers and snack cakes,
            are particularly bad for your cholesterol levels. Not only do trans
            fats increase your total LDL (“bad”) cholesterol, but they also
            lower your HDL (“good”) cholesterol.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          You may have noticed more food labels now market their products as
          “trans fat-free.” But don’t rely only on this label. In the United
          States, if a food contains less than 0.5 grams of trans fat a serving,
          it can be marked trans fat-free. It may not seem like much, but if you
          eat a lot of foods with a small amount of trans fat, it can add up
          quickly. Instead, read the ingredients list. If a food contains a
          partially hydrogenated oil, that’s a trans fat, and you should look
          for an alternative.
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>
            Limit your dietary cholesterol. Aim for no more than 300 milligrams
            (mg) of cholesterol a day — or less than 200 mg if you have heart
            disease. The most concentrated sources of cholesterol include organ
            meats, egg yolks and whole milk products. Use lean cuts of meat, egg
            substitutes and skim milk instead.
          </li>
          <li>
            Select whole grains. Various nutrients found in whole grains promote
            heart health. Choose whole-grain breads, whole-wheat pasta,
            whole-wheat flour and brown rice. Oatmeal and oat bran are other
            good choices.
          </li>
          <li>
            Stock up on fruits and vegetables. Fruits and vegetables are rich in
            dietary fiber, which can help lower cholesterol. Snack on seasonal
            fruits. Experiment with vegetable-based casseroles, soups and
            stir-fries.
          </li>
          <li>
            Eat heart-healthy fish. Some types of fish — such as cod, tuna and
            halibut — have less total fat, saturated fat and cholesterol than do
            meat and poultry. Salmon, mackerel and herring are rich in omega-3
            fatty acids, which help promote heart health.
          </li>
          <li>
            Drink alcohol only in moderation. Moderate use of alcohol may
            increase your levels of HDL cholesterol — but the benefits aren’t
            strong enough to recommend alcohol for anyone who doesn’t drink
            already. If you choose to drink, do so in moderation. This means no
            more than one drink a day for women and one to two drinks a day for
            men.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">Exercise regularly</p>
        <p className="text-md text-gray-600 mb-12">
          Regular exercise can help improve your cholesterol levels. With your
          doctor’s OK, work up to 30 to 60 minutes of exercise a day. Take a
          brisk daily walk. Ride your bike. Swim laps. To maintain your
          motivation, keep it fun. Find an exercise buddy or join an exercise
          group. And, you don’t need to get all 30 to 60 minutes in one exercise
          session. If you can squeeze in three to six 10-minute intervals of
          exercise, you’ll still get some cholesterol-lowering benefits.
        </p>

        <p className="text-xl text-gray-800 mb-2">Don’t smoke</p>
        <p className="text-md text-gray-600 mb-12">
          If you smoke, stop. Quitting can improve your HDL cholesterol level.
          And the benefits don’t end there. Just 20 minutes after quitting, your
          blood pressure decreases. Within 24 hours, your risk of a heart attack
          decreases. Within one year, your risk of heart disease is half that of
          a smoker’s. Within 15 years, your risk of heart disease is similar to
          that of someone who’s never smoked.
        </p>

        <p className="text-xl text-gray-800 mb-2">Alternative medicine</p>
        <p className="text-md text-gray-600 mb-2">
          Few natural products have been proven to reduce cholesterol, but some
          might be helpful. With your doctor’s OK, consider these
          cholesterol-lowering supplements and products:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Artichoke</li>
          <li>Barley</li>
          <li>
            Beta-sitosterol (found in oral supplements and some margarines, such
            as Promise Activ)
          </li>
          <li>
            Blond psyllium (found in seed husk and products such as Metamucil)
          </li>
          <li>Garlic</li>
          <li>Oat bran (found in oatmeal and whole oats)</li>
          <li>
            Sitostanol (found in oral supplements and some margarines, such as
            Benecol)
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          You may have also heard of another supplement to reduce cholesterol,
          red yeast rice. Some brands of red yeast rice contain lovastatin, the
          active ingredient in the drug Mevacor. This can be unsafe, since
          there’s no way to determine the quantity or quality of the lovastatin
          in the supplement.
        </p>
        <p className="text-md text-gray-600 mb-12">
          If you choose to take cholesterol-lowering supplements, remember the
          importance of a healthy lifestyle. If your doctor prescribes
          medication to reduce your cholesterol, take it as directed. Make sure
          your doctor knows which supplements you’re taking as well.
        </p>

        <p className="text-xl text-gray-800 mb-2">Prevention</p>
        <p className="text-md text-gray-600 mb-2">
          The same heart-healthy lifestyle changes that can lower your
          cholesterol can help prevent you from having high cholesterol in the
          first place. To help prevent high cholesterol, you can:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-10">
          <li>Lose extra pounds and maintain a healthy weight</li>
          <li>Quit smoking</li>
          <li>
            Eat a low-fat, low-salt diet that includes many fruits, vegetables
            and whole grains
          </li>
          <li>Exercise on most days of the week for at least 30 minutes</li>
          <li>Drink alcohol in moderation, if at all</li>
        </ul>
      </div>
    </div>
  );
};

export default Hc;
