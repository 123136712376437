import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import Hc from "../../../components/resources/Hc";

const HighCholesterol = () => {
  return (
    <Layout>
      <PageHeader text="High Cholesterol" />
      <Hc />
      <Conditions />
    </Layout>
  );
};

export default HighCholesterol;
